body {
  font-family: 'Gotham' !important;
  margin: 0;
}
body::-webkit-scrollbar { width: 0 !important; font-family: 'Gotham' !important; }
div::-webkit-scrollbar { width: 0 !important; font-family: 'Gotham' !important; }


code {
  font-family: 'Gotham' !important;
}

.parentDiv{
  height: 100% !important;
  width: 100% !important;
  overflow: hidden !important;
}
.childDiv{
  position: absolute !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  overflow: auto !important;
}

.pages{
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  bottom: -10px !important;
  right: -20px !important; 
  overflow: scroll !important;
}

.reload{
  margin: 20px 0;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: "center";
  border-radius: 4px;
  display: "inherit";
}

/*font ant-col*/
:where(.css-dev-only-do-not-override-dassf7)[class^="ant-col"], :where(.css-dev-only-do-not-override-dassf7)[class*=" ant-col"] {
  font-family: 'Gotham' !important;
 }

 :where(.css-dassf7)[class^="ant-row"], :where(.css-dassf7)[class*=" ant-row"] {
  font-family: 'Gotham' !important;
 }

 :where(.css-dassf7)[class^="ant-col"], :where(.css-dassf7)[class*=" ant-col"] {
  font-family: 'Gotham' !important;
 }
 
 :where(.css-dassf7).ant-modal {
  font-family: 'Gotham' !important;
 }

 .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 4px !important;
  display: flex;
  text-align: center;
  font-size: 14px !important;
  /* height: 3px !important; */
 }
 
 @font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Medium.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gotham-Medium.eot') format('embedded-opentype'), /* IE6-IE8 */
     url('./fonts/Gotham-Medium.woff') format('woff'), /* Modern Browsers */
     url('./fonts/Gotham-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('./fonts/Gotham-Medium.ttf') format('svg'); /* Legacy iOS */
   font-weight: 700;
   font-style: normal;
 }
 
 @font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Bold.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gotham-Bold.eot') format('embedded-opentype'), /* IE6-IE8 */
     url('./fonts/Gotham-Bold.woff') format('woff'), /* Modern Browsers */
     url('./fonts/Gotham-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('./fonts/Gotham-Bold.ttf') format('svg'); /* Legacy iOS */
   font-weight: 400;
   font-style: normal;
 }

 @font-face {
  font-family: 'Gotham';
  src: url('./fonts/Gotham-Book.eot'); /* IE9 Compat Modes */
  src: url('./fonts/Gotham-Book.eot') format('embedded-opentype'), /* IE6-IE8 */
     url('./fonts/Gotham-Book.woff') format('woff'), /* Modern Browsers */
     url('./fonts/Gotham-Book.ttf')  format('truetype'), /* Safari, Android, iOS */
     url('./fonts/Gotham-Book.ttf') format('svg'); /* Legacy iOS */
   font-weight: 300;
   font-style: normal;
 }
